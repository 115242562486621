export const AbtisConfig = {
    featureFlags: {
        showPluginGallery: false,
        showSettings: false,
        showDocumentVectorBaseSelector: false,
        showDocumentEmbeddingsHelpText: false,
        showExtendedNewBotMenu: false,
        showDownloadChatButton: false,
        showShareChatButton: false,
        showPromptForChatInfo: false,
        showDocumentUploadInChatButton: false,
        showRelevanceScore: false,
        showAbtisCustomLoadingScreen: true,
        showAbtisLoginScreen: true,
        showAbtisNewChatButton: true,
    },
    localStorageKeys: {
        abtisChatbotDarkmodeEnabledName: 'abtisChatbotDarkmodeEnabled',
    },
    versionNumber: '1.3.0',
};
